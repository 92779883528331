/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: Summer
 * @Date: 2021-04-29 14:26:06
 * @LastEditors: Summer
 * @LastEditTime: 2021-05-11 17:16:32
 */
export default [
  {
    path: '/',
    name: 'newHome',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/index.vue')
  },
  {
    path: '/lodop',
    name: 'lodopvue',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexLodop.vue')
  },
  {
    path: '/fredMeyer',
    name: 'Fred Meyer',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexFredMeyer.vue')
  },
  {
    path: '/safeway',
    name: 'safeway',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexSafeway.vue')
  },
  {
    path: '/walgreens',
    name: 'walgreens',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/print/indexWalgreens.vue')
  }
]
